<template>
  <v-card class="rounded-0" style="color: #F3F2ED">
    <v-container class="pa-12">
      <iframe
        width="100%"
        height="520"
        src="https://www.youtube.com/embed/live_stream?channel=UCPQIMQyn4_wG9WL0gGZc9DQ&amp;autoplay=1"
        frameborder="0"
      ></iframe>
      <v-row class="pt-2" no-gutters>
        <v-col cols="12">
          <v-btn class="float-right" color="primary" target="_blank" href="https://www.youtube.com/channel/UCPQIMQyn4_wG9WL0gGZc9DQ/videos">Video Archives »</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>

export default {
  head: {
    title: function () {
      return {
        inner: 'Live Stream'
      }
    }
  }
}
</script>